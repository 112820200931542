import * as types from "../actions/types";
import {
    GET_CUSTOMER_STATUS_FAILURE,
    GET_CUSTOMER_STATUS_REQUEST,
    GET_CUSTOMER_STATUS_SUCCESS,
    GET_WHITE_LABEL_SUCCESS
} from "../actions/types";
import moment from "moment";

const TESTING_CUSTOMER_ID = window.localStorage.getItem('customeridd') || "ea388346-6f1b-4b02-968b-16ffd1681a24";

const userState = {
    user_details: [],
    name: "",
    email: "",
    id: TESTING_CUSTOMER_ID,
    customerId: TESTING_CUSTOMER_ID,
    role: null,
    newUser: true,
    accountStatus: false,
    loading: false,
    error: null,
    trialEndsIn: null,
    companyName: null,
    companyLogo: null,
    signingOut: false,
    token: null,
    customerStatus: null,
    dispatchedStatus: false,
    dispatchedStatusCount: 0
};

function userDetailsReducer(state = userState, { type, payload }) {
    switch (type) {
        case types.USER_API_LOADING: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.USER_API_SUCCESS: {
            //localStorage.setItem("userData", JSON.stringify(payload));
            //localStorage.setItem("signed", JSON.stringify({ signed: true }));
            return {
                ...state,
                user_details: payload,
                newUser: payload.newUser,
                name: payload.b2c_display_name,
                email: payload.emailId,
                id: payload.customerID,
                accountStatus: payload.accountStatus,
                loading: false,
            };
        }
        case types.USER_API_FAIL: {
            return {
                ...state,
                loading: false,
                error: payload,
            };
        }
        case types.SWITCH_TEAM: {
            localStorage.setItem("team", payload.id);
            localStorage.setItem("role", payload.role);
            return {
                ...state,
                customerId: payload.id,
                role: payload.role,
            };
        }
        case "SIGN_OUT": {
            return {
                ...state,
                signingOut: true,
            };
        }
        case "SWITCH_TEAM_CLEAR": {
            return {
                ...state,
                companyName: null,
                companyLogo: null,
                trialEndsIn: null,
                customerStatus: null,
                dispatchedStatus: false,
                dispatchedStatusCount: 0,

            };
        }
        case "ADB2C_idToken": {
            return {
                ...state,
                token: payload,
            };
        }

        case GET_WHITE_LABEL_SUCCESS: {
            return {
                ...state,
                companyName: payload?.companyName || null,
                companyLogo: payload?.Logo || null,
            };
        }

        case GET_CUSTOMER_STATUS_SUCCESS:
            return {
                ...state,
                customerStatus: payload?.status || null, // active / inactive
                dispatchedStatus: false,
                dispatchedStatusCount: (payload?.status)?10:state.dispatchedStatusCount
            };

        case GET_CUSTOMER_STATUS_REQUEST:
            return {
                ...state,
                 dispatchedStatus: true,
                dispatchedStatusCount: state.dispatchedStatusCount + 1,
            };

        case GET_CUSTOMER_STATUS_FAILURE:
            return {
                ...state,
                 dispatchedStatus: false
            };

        default:
            return state;
    }
}
export default userDetailsReducer;
