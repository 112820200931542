import React, {useEffect} from "react";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import Routes from "./routes/index";
import { Worker } from "@react-pdf-viewer/core";
// import {
//   createMuiTheme,
//   MuiThemeProvider as ThemeProvider,
// } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {Dashboard} from "@mui/icons-material";
import DashboardChart from "./pages/Charts/DashboardChart";

const {REACT_APP_CUSTS} = process.env;

const theme = createTheme({
  typography: {
    fontFamily: "Poppins_Regular",
  },
    palette: {
        primary: {
            main: '#3f51b5',
            light: '#7986cb',
            dark: '#303f9f'
        }
    }
});

function App() {
    useEffect(()=>{
        const customer = REACT_APP_CUSTS.split(';');
        window.$(document).ready(()=>{
            const select = window.$('#customer_id_selector_main');
            customer.map(c=>{
                select.append(`<option value="${c.split('=')[1]}" >${c.split('=')[0]}(${c.split('=')[1].slice(0,4)}...)</option>`)
            })
        })
    }, []);

  return (
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js">
          <ThemeProvider theme={theme}>
              <Provider store={store}>
                  <BrowserRouter forceRefresh={true}>
                      <Routes />
                  </BrowserRouter>
              </Provider>
          </ThemeProvider>
      </Worker>
  );
}

export default App;
